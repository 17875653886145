@mixin custom-border-radius($radius: 1rem) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
}

@mixin custom-transition() {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

@mixin custom-transfort($x: -50%, $y: -50%) {
    transform: translate($x, $y);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}


@mixin custom-bar-scrool($height: 0.2rem, $width: 0.2rem) {

    overflow-y: auto;

    &::-webkit-scrollbar {

        width: $width;
        height: $height;
    }

    &::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: rgba(89, 89, 89, 0.5);
        @include custom-border-radius($radius: 0.4);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(89, 89, 89, 0.2);
        border-radius: 20px;
    }
}


@mixin custom-size-modal-react-bootstrap($width: 100%, $height: auto, $widthModalContent: 100%) {

    .modal-dialog {
        width: $width !important;
        // max-width: none;
        --bs-modal-width: auto;

        .modal-content {

            width: $widthModalContent !important;
            height: $height;
        }
    }
}

@mixin custom-size-modal($width: 100%, $height: auto, $widthModalContent: 100%) {

    .modal-dialog {
        width: $width !important;
        // max-width: none;
        --bs-modal-width: auto;

        .modal-content {

            width: $widthModalContent !important;
            height: $height;
        }
    }
}

@mixin custom-btn-delete-edit() {

    .icon {

        font-size: 0.8rem;
    }

    .edit {

        background-color: $green-color;
        padding: 0.2rem 0.6rem;
        @include custom-border-radius($radius: 0.8rem);
        border: 0.05rem solid $green-color;
        @include custom-transition();

        .icon {

            color: $white;
        }

        &:hover {

            background-color: $white;

            .icon {

                color: $green-color;
            }
        }
    }

    .delete {

        background-color: $error;
        padding: 0.2rem 0.6rem;
        @include custom-border-radius($radius: 0.8rem);
        border: 0.05rem solid $error;
        @include custom-transition();

        .icon {

            color: $white;
        }

        &:hover {

            background-color: $white;

            .icon {

                color: $error;
            }
        }
    }
}

@mixin custom-btn-outline-delete-edit() {

    .icon {

        font-size: 0.8rem;
    }

    .edit {

        background-color: $white;
        padding: 0.2rem 0.6rem;
        height: fit-content;
        @include custom-border-radius($radius: 0.8rem);
        border: 0.05rem solid $green-color;
        @include custom-transition();

        .icon {

            color: $green-color;
        }

        &:hover {

            background-color: $green-color;

            .icon {

                color: $white;
            }
        }
    }

    .delete {

        background-color: $white;
        padding: 0.2rem 0.6rem;
        @include custom-border-radius($radius: 0.8rem);
        border: 0.05rem solid $error;
        @include custom-transition();
        height: fit-content;

        .icon {

            color: $error;
        }

        &:hover {

            background-color: $error;

            .icon {

                color: $white;
            }
        }
    }
}


@mixin custom-btn-outline-answer-response-option() {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem;
    background-color: rgba(221, 53, 98, 0.1);
    @include custom-border-radius($radius: 0.5rem);

    .answer {

        color: $white;
        font-size: 0.7rem;
        font-weight: 600;
        padding: 0.4rem 0.5rem;
        @include custom-border-radius($radius: 0.5rem);
        background-color: $white;
        border: 0.05rem solid transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;


        &.correct {

            color: $green-color;
            border-color: $green-color;
        }

        &.error {

            color: $error;
            border-color: $error;
        }
    }
}