// Logout
.modal-logout {

    @include custom-size-modal($width: 25rem, $height: auto);

    .card-custom {

        .content {

            &.content-content {

                p {

                    font-size: 1.1rem;
                    font-weight: 400;
                }
            }
        }

    }
}


// Delete data in table
.modal-delete-date-table {

    @include custom-size-modal($width: 30rem, $height: auto);

    .card-custom {

        .content {

            &.content-content {

                .content-body {
                    
                    height: 6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p{

                        font-size: 1.1rem;
                        font-weight: 400;
                    }
                }
            }
        }

    }
}

// Delete data in table
.modal-training-add-optin-answer {

    @include custom-size-modal($width: 30rem, $height: auto);

    .card-custom {

        .content {

            &.content-content {

                .content-body {
                     
                    display: grid;
                    grid-template-columns: 1fr;


                    form{

                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 0.5rem;
                        margin: 1.5rem 0;
                    }
                }
            }
        }

    }
} 



// Forms add and edit section
.modal-form-section-add, .modal-form-section-edit {

    @include custom-size-modal($width: 30rem, $height: auto);

    .card-custom {

        .content {

            &.content-content {

                .content-body {
                     
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 1rem;

                    form{

                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 0.5rem;
                        margin: 1.5rem 0;

                        .item{

                            display: grid;
                            grid-template-columns: 1fr;
                            margin: 0.5rem 0 1.5rem 0;
                        }
                    }
                }
            }
        }

    }
} 