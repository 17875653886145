@import './mixin';


.forms-all {

    margin: 1rem 5rem;
}

.errors-fields {

    color: $error;
    font-size: 0.8rem;
}

label {

    font-size: 1rem;
}

span {

    &.spinner-border {

        color: $primary;
        display: block;
        margin-right: 0.5rem;

    }
}

button {

    &.btn-custom-primary,
    &.btn-custom-desable,
    &.btn-custom-secondary,
    &.btn-custom-primary-icon,
    &.btn-custom-canceled,
    &.btn-custom-green-icon {

        @include custom-border-radius($radius: 0.5rem);
        width: 100%;
        padding: 0.4rem 1rem;
        @include custom-transition();
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 0.9rem;
    }

    &.btn-custom-canceled {

        background-color: $gray-light;
        border: 0.05rem solid $gray-light;
        color: $white;

        &:hover {

            background-color: $white;
            color: $gray-light;
        }
    }

    &.btn-custom-primary-icon {

        background-color: white;
        border: 0.05rem solid $primary;

        span,
        .icon {

            display: block;
            color: $primary;
            font-size: 0.8rem;
        }

        span {

            margin-left: 0.3rem;
        }

        .icon {

            margin-top: 0.1rem;
        }

        &:hover {

            background-color: $primary;
            color: $white;
            border-color: $primary;

            span,
            .icon {

                color: $white;
            }
        }
    }

    &.btn-custom-green-icon {

        background-color: white;
        border: 0.05rem solid $green-color;

        span,
        .icon {

            display: block;
            color: $green-color;
            font-size: 0.8rem;
        }

        span {

            margin-left: 0.3rem;
        }

        .icon {

            margin-top: 0.1rem;
        }

        &:hover {

            background-color: $green-color;
            color: $white;
            border-color: $green-color;

            span,
            .icon {

                color: $white;
            }
        }
    }

    &.btn-custom-primary {

        background-color: white;
        border: 0.05rem solid $primary;
        color: $primary;

        &:hover {

            background-color: $primary;
            color: $white;
            border-color: $primary;
        }
    }

    &.btn-custom-desable {

        color: $desable-color;
        background-color: $desable-background;
        border: 0.05rem solid $desable-color;
    }

    &.btn-custom-secondary {

        background-color: $primary;
        border: 0.05rem solid $primary;
        color: $white;
        @include custom-transition();

        &:hover {

            background-color: $white;
            color: $primary;
            border-color: $primary;
        }
    }
}

input {

    font-size: 1rem;
    background-color: $white;

    &.form-control {

        background-color: $white;

        &:focus {
            border: 0.05rem solid $gray-light;
            outline: 0;
            box-shadow: none;
        }

        &:hover {

            border-color: $primary;
        }
    }

    &.form-check-input {

        &:focus {
            border: 0.05rem solid $gray-light;
            outline: 0;
            box-shadow: none;
        }

        &:hover {

            border-color: $primary;
        }
    }

    &[type="checkbox"]:checked,
    &[type="radio"]:checked {
        background-color: $primary;
        border-color: $primary;
    }

}

.form-switch{

    .form-check-input{

        height: 1.4rem;
        width: 2.5rem !important;
        cursor: pointer;
    }
}

textarea {
    &.form-control {

        &:focus {
            border: 0.05rem solid $gray-light;
            outline: 0;
            box-shadow: none;
        }

        &:hover {

            border-color: $primary;
        }
    }
}

select {

    &.form-select {
        &:focus {
            border: 0.05rem solid $gray-light;
            outline: 0;
            box-shadow: none;
        }

        &:hover {

            border-color: $primary;
        }

        option {

            &:hover {

                background-color: gray;
            }
        }
    }
}

.custom-form {

    margin-top: 2rem;
    
    .grid-custom-two-column {

        display: flex;
        justify-content: end;

        .btn-custom-canceled,
        .btn-custom-secondary, .btn-custom-desable {


            width: fit-content;
            // margin-bottom: 1rem;
        }
    }
}