@import "../color";
@import "../breakpoint";
@import "../mixin";

#login {

    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: $white;

    .content {
        width: 30rem;
        padding: 2rem 2.5rem;
        background-color: $background-light;
        position: absolute;
        top: 50%;
        left: 50%;
        @include custom-transfort($x: -50%, $y: -50%);
        @include custom-border-radius($radius: 1rem);
        box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.15);

        .image{

            display: flex;
            justify-content: center;
            align-items: center;
            
            img {
    
                background-color: $white;
                @include custom-border-radius($radius: 1rem);
                margin-bottom: 2rem;
                box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.15);
            }
        }


        .btn-custom-secondary,
        .btn-custom-desable {

            margin-top: 1.5rem;
        }
    }
}