@import '../mixin';
@import '../color';


#admin-traning-section {

    .section-content-full {

        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem; 
        
        .spinner-loading {

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .section-content {

            border: 0.05rem solid rgba(0, 0, 0, 0.2);
            @include custom-border-radius($radius: 0.5rem);
            padding: 1rem;

            @include custom-btn-delete-edit();

            .title-section {

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1rem;

                .item {

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.3rem;

                    &:first-child {


                        span {

                            font-size: 1rem;
                        }

                        strong {

                            font-weight: 500;
                            font-size: 1.2rem;
                        }
                    }
                }
            }

            .content-section-chapter {

                display: grid;
                grid-template-columns: 1fr;
                gap: 0.5rem;

                .content {

                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    gap: 1rem;

                    .model-chapter {

                        background-color: #fee5e852;
                        padding: 0.5rem;
                        @include custom-border-radius($radius: 0.5rem);

                        .title {

                            font-size: 1rem;
                        }

                        .media {

                            width: 100%;
                            height: 9rem;

                            img,
                            video {

                                width: 100%;
                                height: 100%;
                            }
                        }

                        .btn-edit-delete {

                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            gap: 1rem;
                            margin: 0.6rem 0 0.2rem 0;

                            .edit {

                                background-color: $white;

                                .icon {

                                    color: $green-color;
                                }

                                &:hover {

                                    background-color: $green-color;

                                    .icon {

                                        color: $white;
                                    }
                                }
                            }

                            .delete {

                                background-color: $white;

                                .icon {

                                    color: $error;
                                }

                                &:hover {

                                    background-color: $error;

                                    .icon {

                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }

                .custom-btn-header {

                    gap: 0.5rem;
                }
            }
        }
    }
}

#admin-section-quiz {

    .content-header {

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .conetnt-quiz-list {

        .spinner-loading {

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .content-quiz {

            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 1rem;

            .content {

                border: 0.05rem solid rgba(0, 0, 0, 0.2);
                @include custom-border-radius($radius: 0.5rem);
                padding: 1rem;
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;

                .sub-content {

                    display: grid;
                    grid-template-columns: 1fr;

                    .hearder-content {


                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        span {

                            &:first-child {

                                font-size: 1rem;
                                font-weight: 600;
                            }
                        }
                    }

                    .body-content {

                        margin: 1rem 0;
                        display: grid;
                        grid-template-columns: 1fr;
                        gap: 0.5rem;

                        .item {

                            &:first-child {

                                width: 100%;
                                height: 9rem;

                                img,
                                video {

                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            &:last-child {

                                margin-top: 1rem;

                                span {

                                    font-size: 1rem;

                                    &:last-child {

                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }

                    .footer-content {

                        @include custom-btn-outline-answer-response-option();
                    }
                }

                .item-btn {

                    @include custom-btn-outline-delete-edit();
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: flex-end;
                    gap: 0.3rem;
                }
            }
        }
    }
}

#admin-section-forms-add,
#admin-section-forms-edit,
#admin-quiz-forms-add,
#admin-quiz-forms-edit {

    .custom-form {

        @include custom-bar-scrool($height: 0.2rem, $width: 0.4rem);

        .body-formulaire {

            .content-option-answers {

                min-height: 3rem;
                @include custom-btn-outline-answer-response-option();

            }


            .link-add-option {

                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 1rem;

                span {

                    display: block;

                    &:first-child {

                        width: 1.6rem;
                        height: 1.6rem;
                        background-color: $primary;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        @include custom-border-radius($radius: 0.5rem);

                        .icon {

                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.custom-form {

    .custom-display-media-description{
        
        display:  grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .custom-display-media-without-description {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        .content {

            position: relative;
            z-index: 1;
            height: 9rem;

            .description{

                position: absolute;
                bottom: 0.2rem;
                right: 0.5rem;
                width: 7rem;
                z-index: 2;
                background-color: $green-color;
                padding: 0.1rem 0.5rem;
                @include custom-border-radius($radius: 0.5rem);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border: 0.05rem solid $green-color;
                cursor: pointer;

                span{

                    font-size: 0.8rem;
                    font-weight: 400;
                    color: $white;
                }

                .icon-comment{

                    color: $white;
                    font-size: 1rem;
                }

                &:hover{

                    background-color: $white;
                    
                    span, .icon-comment{

                        color: $green-color;
                    }
                }
            }

            .icon-close {
                
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                z-index: 3;
                cursor: pointer;
                color: $error;
                font-size: 1.5rem;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                box-shadow:0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
                background-color: transparent;
                @include custom-border-radius($radius: 100%);
            }

            img, video {

                width: 9rem;
                height: 100%;
                cursor: pointer;
            }
        }

    }
}