#field36-content-admin {

    background-color: $background-light;
    position: relative;
    display: flex;

    #sidebar {

        position: fixed;
        width: 18rem;
        height: 100vh;
        z-index: 1000;
        background-color: $white;
        // background-color: #fef2f3;
        box-shadow: 0.05rem 0rem 1.5rem rgba(0, 0, 0, 0.1);

        .sidebar-content {

            .logo {

                display: flex;
                justify-content: center;
                margin: 0.2rem 0rem;

                img {

                    height: 5rem;
                    width: 5rem;
                    background-color: white;
                    @include custom-border-radius($radius: 0.5rem);
                    box-shadow: 0.05rem 0.05rem 1rem rgba(0, 0, 0, 0.1);
                }
            }

            .sd-body {

                ul {

                    &.navbar-nav {

                        display: grid;
                        grid-template-columns: 1fr;

                        li {

                            &.item-li {

                                .item-link {

                                    color: $gray-bold;
                                    padding: 0.6rem 1rem;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    text-decoration: none;
                                    font-size: 1rem;
                                    font-weight: 400;
                                    // border-top-right-radius: 1rem;
                                    // border-bottom-right-radius: 1rem;
                                    @include custom-transition();

                                    &:hover:not(&.active) {

                                        background-color: $white;
                                        color: $primary;

                                        .icon {

                                            color: $primary;
                                        }
                                    }

                                    .icon {

                                        font-size: 1.3rem;
                                        color: $gray-bold;
                                        margin-right: 1rem;
                                    }

                                    &.active {

                                        background-color: $primary;
                                        color: $white;

                                        .icon {

                                            color: $white;
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #navbar {

        width: 100vw;

        .navbar {

            position: sticky;
            top: 0rem;
            width: 100%;
            z-index: 999;
            height: 5rem;
            background-color: $white;
            box-shadow: 0rem 0.05rem 1.5rem rgba(0, 0, 0, 0.1);

            .content-navbar {

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                span {

                    margin-left: 20rem;
                }

                .left {

                    margin-right: 2rem;

                    span {

                        margin-right: 1rem;
                    }
                }
            }
        }

        .full-content {

            width: 100%;
            padding-left: 18rem;
            padding-top: 0.5rem;
            height: calc(100% - 5rem);
            background-color: $white;

            .card-custom {

                margin: 0.5rem 1rem 1rem 1rem;
                // height: calc(100vh - 7.5rem);
                background-color: $white;
                padding: 1.5rem;
                @include custom-border-radius($radius: 0.5rem);
                box-shadow: 0.01rem 0.01rem 0.6rem rgba(0, 0, 0, 0.1);
            }
        }
    }
}


// Menu content
ul {

    margin: 0;
    padding: 0;

    &.custom-navbar-nav {
    
        &.light-bar {
    
            display: grid; 
            @include custom-border-radius($radius: 0.8rem);
            position: relative;
    
            &::after {
    
                content: "";
                display: block;
                width: 100%;
                height: 0.05rem;
                background-color: $desable-color;
                opacity: 0.4;
                position: absolute;
                bottom: 0;
                left: 0;
            }
    
            li {
    
                list-style-type: none;
    
                &.nav-item {
    
                    .nav-link {
    
                        padding: 0.5rem 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0.05rem solid transparent;
                        border-top-left-radius: 1rem;
                        border-top-right-radius: 1rem;
    
                        &:hover:not(&.active) {
    
                            background-color: $white;
                            color: $primary;
                            border-color: $primary;
                        }
    
                        &.active {
    
                            position: relative;
                            color: $primary;
    
                            &::after {
    
                                content: "";
                                display: block;
                                width: 100%;
                                height: 0.3rem;
                                position: absolute;
                                bottom: -0.16rem;
                                z-index: 2;
                                left: 0rem;
                                background-color: $primary;
                                @include custom-border-radius($radius: 1rem);
                            }
                        }
                    }
                }
            }
        }
        &.bolder-bar{
            
            list-style-type: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
    
            margin: 0;
            padding: 0;
    
            .nav-item {
    
    
                &:first-child {
    
                    .nav-link {
    
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }
                }
    
                &:nth-child(2) {
    
                    .nav-link {
    
                        border-left: none;
                        border-right: none;
                    }
                }
    
                &:last-child {
    
                    .nav-link {
    
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }
                }
    
                .nav-link {
    
                    text-decoration: none;
                    padding: 0.4rem 0.6rem;
                    background-color: $white;
                    border: 0.05rem solid $primary;
    
                    &.active {
    
                        color: $white;
                        background-color: $primary;
    
                    }
                }
            }
        }
    }
}