@import "./admin/login";
@import "./admin/sidebar-navbar";
@import "./tables";
@import "./forms";
@import "./modal";
@import "./admin/traning";


h4 {

    &.title {

        font-size: 1.3rem;
        font-weight: 500;
        color: $gray-bold;
        width: fit-content;

        &::after {

            content: '';
            display: block;
            width: 100%;
            height: 0.1rem;
            background-color: $primary;
        }
    }
}


.cusom-go-back-page {

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}



.custom-btn-header {

    display: flex;
    flex-direction: row;
    align-items: center;

    &.custom-end {

        justify-content: flex-end;
        margin-bottom: 0.5rem;

        .link-btn-custom {

            .btn-custom-secondary,
            .btn-custom-primary,
            .btn-custom-green-icon {

                width: fit-content;
            }
        }
    }
}


// FootSolidier
#admin-fs {

    button {

        span {

            &.fs-kyc {


                color: $white;
                display: block;
                margin-left: 1rem;
                font-weight: 500;
                font-size: 1.1rem;
            }

        }

        &:hover {

            span {

                &.fs-kyc {

                    color: $green-color;
                }
            }
        }
    }
}

#admin-fs-validation {

    .header-custom {

        display: grid;
        grid-template-columns: 6rem 1fr;
        margin-bottom: 1.5rem;

        h5 {

            &.title {

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
            }
        }
    }

    .item-content {

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .item-key-value {

            display: grid;
            grid-template-columns: 10rem 1fr;
        }
    }

    .kmedia {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {

            width: 18rem;
        }
    }
}

.admin-forms {

    .btn-custom-primary-icon {

        width: fit-content;
        margin-bottom: 1rem;
    }
}


#admin-campaign-layouts{
 
    .menu-campaign {
    
        margin-bottom: 1rem;
    
        ul {
    
            &.custom-navbar-nav {
    
                &.light-bar {
    
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}

#admin-area-layouts{
 
    .menu-area {
    
        margin-bottom: 1rem;
    
        ul {
    
            &.custom-navbar-nav {
    
                &.light-bar {
    
                    grid-template-columns: repeat(6, 1fr);
                }
            }
        }
    }
}

#admin-settings {

    #menu-setting {

        margin-bottom: 1rem;

        ul {

            &.custom-navbar-nav {

                &.light-bar {

                    grid-template-columns: repeat(7, 1fr);
                }
            }
        }
    }

    .admin-forms {

        margin-top: 3rem;
    }
}

#admin-account {

    .custom-display-info {

        gap: 1rem;

        .loading-for-display-data {

            display: flex;
            justify-content: center;
            align-items: center;

        }

        .item-display {

            display: grid;
            grid-template-columns: 12rem 1fr;
        }
    }

    .custom-display-change-password {

        margin-top: 2rem;

        form {

            .item-form {

                display: grid;
                grid-template-columns: 1fr;
            }

            .custom-btn-submit-position {

                display: flex;
                justify-content: flex-end;

                button {

                    width: fit-content;
                }
            }
        }
    }
}

#admin-traning-edit {

    .menu-traning {

        margin-top: 1rem;

    }
}

.comapaign-forms{

    .custom-form {

        .item-selected {

            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            gap: 0.5rem;

            span {

                background: $gray-very-light;
                color: $green-color;
                padding: 0.1rem 0.5rem;
                font-size: 0.8rem;
                font-weight: 400;
                @include custom-border-radius($radius: 0.4rem);
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.5rem;

                .icon {

                    color: $error;
                    font-size: 1.5rem;
                    padding: 0.1rem;
                    background: transparent;
                    border: 0.05rem solid transparent;
                    @include custom-border-radius($radius: 50%);

                    &:hover {

                        border-color: $error;
                    }
                }
            }
        }
    }
}