.responsible-table-custom {

    @include custom-bar-scrool($height: 0.2rem, $width: 0.2rem);
    // height: 10rem;
    // background-color: red;
    // width: 10rem;

    table {

        &.table {

            box-shadow: 0.1rem 0.04rem 0.1rem rgba(0, 0, 0, 0.3); 
            @include custom-border-radius($radius: 0.6rem);

            thead {
                position: sticky;
                top: 0rem;

                tr {

                    background-color: transparent;

                    th {

                        background-color: $gray-light;
                        color: $white;
                        font-size: 1rem;
                        font-weight: 500;
                        text-align: start;
                        vertical-align: middle;

                        &:first-child {

                            border-top-left-radius: 0.6rem;
                        }

                        &:last-child {

                            border-top-right-radius: 0.6rem;
                        }
                    }
                }
            }

            tbody,
            tfoot {

                tr {

                    background-color: transparent;
                    height: 2.1rem;

                    &:nth-child(odd) {

                        td {
                            background-color: #f6f6f6;
                        }
                    }

                    &:nth-child(even) {

                        td {

                            background-color: rgba(246, 246, 246, 0.1);
                        }
                    }



                    &:last-child {

                        td {
                            &:first-child {

                                border-bottom-left-radius: 0.6rem; 
                            }
                            
                            &:last-child {
                                
                                border-bottom-right-radius: 0.6rem; 
                            }
                        }
                    }

                    td {

                        font-size: 0.8rem;
                        background-color: transparent;
                        font-weight: 400;
                        text-align: start;
                        vertical-align: middle;
                        padding: 0.1rem;
                        border: none;

                        &:first-child {

                            padding-left: 0.5rem; 
                        }
 
                        &.custom-center-loading {

                            text-align: center;
                        }

                        &.custom-loading-data-td {

                            display: none;
                            // background-color: blue;
                        }

                        &.td-action {

                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 0.6rem;

                            .td-link {

                                padding: 0.2rem;
                                @include custom-border-radius($radius: 0.4rem);
                                @include custom-transition();

                                .icon {

                                    font-size: 0.8rem;
                                }

                                &.quiz {

                                    background-color: $white;
                                    border: 0.05rem solid $gray-bold;
                                    color: $gray-bold;

                                    &:hover {

                                        color: $white;
                                        background-color: $gray-bold;
                                    }
                                }

                                &.edit {

                                    background-color: $white;
                                    border: 0.05rem solid $green-color;
                                    color: $green-color;

                                    &:hover {

                                        color: $white;
                                        background-color: $green-color;
                                    }
                                }

                                &.delete {

                                    background-color: $white;
                                    border: 0.05rem solid $error;
                                    color: $error;

                                    &:hover {

                                        color: $white;
                                        background-color: $error;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}