
$white: #ffffff;
$desable-background: #fee5e8;
$desable-color: #f67690;
$green-color: #00b809;
$primary : #DD3563;
$secondary : #a70d3b;
$background-light : #f9f7f7;
$error : #d70000;
$gray-very-light : rgba(231, 231, 231, 0.5);
$gray-light : #b0b0b0;
$gray-normal : #6d6d6d;
$gray-bold : #454545;