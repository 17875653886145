@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
#login {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #ffffff;
}
#login .content {
  width: 30rem;
  padding: 2rem 2.5rem;
  background-color: #f9f7f7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.15);
}
#login .content .image {
  display: flex;
  justify-content: center;
  align-items: center;
}
#login .content .image img {
  background-color: #ffffff;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0.02rem 0.02rem 0.5rem rgba(0, 0, 0, 0.15);
}
#login .content .btn-custom-secondary,
#login .content .btn-custom-desable {
  margin-top: 1.5rem;
}

#field36-content-admin {
  background-color: #f9f7f7;
  position: relative;
  display: flex;
}
#field36-content-admin #sidebar {
  position: fixed;
  width: 18rem;
  height: 100vh;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0.05rem 0rem 1.5rem rgba(0, 0, 0, 0.1);
}
#field36-content-admin #sidebar .sidebar-content .logo {
  display: flex;
  justify-content: center;
  margin: 0.2rem 0rem;
}
#field36-content-admin #sidebar .sidebar-content .logo img {
  height: 5rem;
  width: 5rem;
  background-color: white;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  box-shadow: 0.05rem 0.05rem 1rem rgba(0, 0, 0, 0.1);
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav {
  display: grid;
  grid-template-columns: 1fr;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link {
  color: #454545;
  padding: 0.6rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link:hover:not(#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link.active) {
  background-color: #ffffff;
  color: #DD3563;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link:hover:not(#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link.active) .icon {
  color: #DD3563;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link .icon {
  font-size: 1.3rem;
  color: #454545;
  margin-right: 1rem;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link.active {
  background-color: #DD3563;
  color: #ffffff;
}
#field36-content-admin #sidebar .sidebar-content .sd-body ul.navbar-nav li.item-li .item-link.active .icon {
  color: #ffffff;
}
#field36-content-admin #navbar {
  width: 100vw;
}
#field36-content-admin #navbar .navbar {
  position: sticky;
  top: 0rem;
  width: 100%;
  z-index: 999;
  height: 5rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.05rem 1.5rem rgba(0, 0, 0, 0.1);
}
#field36-content-admin #navbar .navbar .content-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#field36-content-admin #navbar .navbar .content-navbar span {
  margin-left: 20rem;
}
#field36-content-admin #navbar .navbar .content-navbar .left {
  margin-right: 2rem;
}
#field36-content-admin #navbar .navbar .content-navbar .left span {
  margin-right: 1rem;
}
#field36-content-admin #navbar .full-content {
  width: 100%;
  padding-left: 18rem;
  padding-top: 0.5rem;
  height: calc(100% - 5rem);
  background-color: #ffffff;
}
#field36-content-admin #navbar .full-content .card-custom {
  margin: 0.5rem 1rem 1rem 1rem;
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  box-shadow: 0.01rem 0.01rem 0.6rem rgba(0, 0, 0, 0.1);
}

ul {
  margin: 0;
  padding: 0;
}
ul.custom-navbar-nav.light-bar {
  display: grid;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  position: relative;
}
ul.custom-navbar-nav.light-bar::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.05rem;
  background-color: #f67690;
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
}
ul.custom-navbar-nav.light-bar li {
  list-style-type: none;
}
ul.custom-navbar-nav.light-bar li.nav-item .nav-link {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.05rem solid transparent;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
ul.custom-navbar-nav.light-bar li.nav-item .nav-link:hover:not(ul.custom-navbar-nav.light-bar li.nav-item .nav-link.active) {
  background-color: #ffffff;
  color: #DD3563;
  border-color: #DD3563;
}
ul.custom-navbar-nav.light-bar li.nav-item .nav-link.active {
  position: relative;
  color: #DD3563;
}
ul.custom-navbar-nav.light-bar li.nav-item .nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.3rem;
  position: absolute;
  bottom: -0.16rem;
  z-index: 2;
  left: 0rem;
  background-color: #DD3563;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
ul.custom-navbar-nav.bolder-bar {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
}
ul.custom-navbar-nav.bolder-bar .nav-item:first-child .nav-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
ul.custom-navbar-nav.bolder-bar .nav-item:nth-child(2) .nav-link {
  border-left: none;
  border-right: none;
}
ul.custom-navbar-nav.bolder-bar .nav-item:last-child .nav-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
ul.custom-navbar-nav.bolder-bar .nav-item .nav-link {
  text-decoration: none;
  padding: 0.4rem 0.6rem;
  background-color: #ffffff;
  border: 0.05rem solid #DD3563;
}
ul.custom-navbar-nav.bolder-bar .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #DD3563;
}

.responsible-table-custom {
  overflow-y: auto;
}
.responsible-table-custom::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}
.responsible-table-custom::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: rgba(89, 89, 89, 0.5);
  border-radius: 0.4;
  -webkit-border-radius: 0.4;
  -moz-border-radius: 0.4;
  -ms-border-radius: 0.4;
  -o-border-radius: 0.4;
}
.responsible-table-custom::-webkit-scrollbar-track {
  background-color: rgba(89, 89, 89, 0.2);
  border-radius: 20px;
}
.responsible-table-custom table.table {
  box-shadow: 0.1rem 0.04rem 0.1rem rgba(0, 0, 0, 0.3);
  border-radius: 0.6rem;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  -ms-border-radius: 0.6rem;
  -o-border-radius: 0.6rem;
}
.responsible-table-custom table.table thead {
  position: sticky;
  top: 0rem;
}
.responsible-table-custom table.table thead tr {
  background-color: transparent;
}
.responsible-table-custom table.table thead tr th {
  background-color: #b0b0b0;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  text-align: start;
  vertical-align: middle;
}
.responsible-table-custom table.table thead tr th:first-child {
  border-top-left-radius: 0.6rem;
}
.responsible-table-custom table.table thead tr th:last-child {
  border-top-right-radius: 0.6rem;
}
.responsible-table-custom table.table tbody tr,
.responsible-table-custom table.table tfoot tr {
  background-color: transparent;
  height: 2.1rem;
}
.responsible-table-custom table.table tbody tr:nth-child(odd) td,
.responsible-table-custom table.table tfoot tr:nth-child(odd) td {
  background-color: #f6f6f6;
}
.responsible-table-custom table.table tbody tr:nth-child(even) td,
.responsible-table-custom table.table tfoot tr:nth-child(even) td {
  background-color: rgba(246, 246, 246, 0.1);
}
.responsible-table-custom table.table tbody tr:last-child td:first-child,
.responsible-table-custom table.table tfoot tr:last-child td:first-child {
  border-bottom-left-radius: 0.6rem;
}
.responsible-table-custom table.table tbody tr:last-child td:last-child,
.responsible-table-custom table.table tfoot tr:last-child td:last-child {
  border-bottom-right-radius: 0.6rem;
}
.responsible-table-custom table.table tbody tr td,
.responsible-table-custom table.table tfoot tr td {
  font-size: 0.8rem;
  background-color: transparent;
  font-weight: 400;
  text-align: start;
  vertical-align: middle;
  padding: 0.1rem;
  border: none;
}
.responsible-table-custom table.table tbody tr td:first-child,
.responsible-table-custom table.table tfoot tr td:first-child {
  padding-left: 0.5rem;
}
.responsible-table-custom table.table tbody tr td.custom-center-loading,
.responsible-table-custom table.table tfoot tr td.custom-center-loading {
  text-align: center;
}
.responsible-table-custom table.table tbody tr td.custom-loading-data-td,
.responsible-table-custom table.table tfoot tr td.custom-loading-data-td {
  display: none;
}
.responsible-table-custom table.table tbody tr td.td-action,
.responsible-table-custom table.table tfoot tr td.td-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link,
.responsible-table-custom table.table tfoot tr td.td-action .td-link {
  padding: 0.2rem;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link .icon,
.responsible-table-custom table.table tfoot tr td.td-action .td-link .icon {
  font-size: 0.8rem;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.quiz,
.responsible-table-custom table.table tfoot tr td.td-action .td-link.quiz {
  background-color: #ffffff;
  border: 0.05rem solid #454545;
  color: #454545;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.quiz:hover,
.responsible-table-custom table.table tfoot tr td.td-action .td-link.quiz:hover {
  color: #ffffff;
  background-color: #454545;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.edit,
.responsible-table-custom table.table tfoot tr td.td-action .td-link.edit {
  background-color: #ffffff;
  border: 0.05rem solid #00b809;
  color: #00b809;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.edit:hover,
.responsible-table-custom table.table tfoot tr td.td-action .td-link.edit:hover {
  color: #ffffff;
  background-color: #00b809;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.delete,
.responsible-table-custom table.table tfoot tr td.td-action .td-link.delete {
  background-color: #ffffff;
  border: 0.05rem solid #d70000;
  color: #d70000;
}
.responsible-table-custom table.table tbody tr td.td-action .td-link.delete:hover,
.responsible-table-custom table.table tfoot tr td.td-action .td-link.delete:hover {
  color: #ffffff;
  background-color: #d70000;
}

.forms-all {
  margin: 1rem 5rem;
}

.errors-fields {
  color: #d70000;
  font-size: 0.8rem;
}

label {
  font-size: 1rem;
}

span.spinner-border {
  color: #DD3563;
  display: block;
  margin-right: 0.5rem;
}

button.btn-custom-primary, button.btn-custom-desable, button.btn-custom-secondary, button.btn-custom-primary-icon, button.btn-custom-canceled, button.btn-custom-green-icon {
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  width: 100%;
  padding: 0.4rem 1rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}
button.btn-custom-canceled {
  background-color: #b0b0b0;
  border: 0.05rem solid #b0b0b0;
  color: #ffffff;
}
button.btn-custom-canceled:hover {
  background-color: #ffffff;
  color: #b0b0b0;
}
button.btn-custom-primary-icon {
  background-color: white;
  border: 0.05rem solid #DD3563;
}
button.btn-custom-primary-icon span,
button.btn-custom-primary-icon .icon {
  display: block;
  color: #DD3563;
  font-size: 0.8rem;
}
button.btn-custom-primary-icon span {
  margin-left: 0.3rem;
}
button.btn-custom-primary-icon .icon {
  margin-top: 0.1rem;
}
button.btn-custom-primary-icon:hover {
  background-color: #DD3563;
  color: #ffffff;
  border-color: #DD3563;
}
button.btn-custom-primary-icon:hover span,
button.btn-custom-primary-icon:hover .icon {
  color: #ffffff;
}
button.btn-custom-green-icon {
  background-color: white;
  border: 0.05rem solid #00b809;
}
button.btn-custom-green-icon span,
button.btn-custom-green-icon .icon {
  display: block;
  color: #00b809;
  font-size: 0.8rem;
}
button.btn-custom-green-icon span {
  margin-left: 0.3rem;
}
button.btn-custom-green-icon .icon {
  margin-top: 0.1rem;
}
button.btn-custom-green-icon:hover {
  background-color: #00b809;
  color: #ffffff;
  border-color: #00b809;
}
button.btn-custom-green-icon:hover span,
button.btn-custom-green-icon:hover .icon {
  color: #ffffff;
}
button.btn-custom-primary {
  background-color: white;
  border: 0.05rem solid #DD3563;
  color: #DD3563;
}
button.btn-custom-primary:hover {
  background-color: #DD3563;
  color: #ffffff;
  border-color: #DD3563;
}
button.btn-custom-desable {
  color: #f67690;
  background-color: #fee5e8;
  border: 0.05rem solid #f67690;
}
button.btn-custom-secondary {
  background-color: #DD3563;
  border: 0.05rem solid #DD3563;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
button.btn-custom-secondary:hover {
  background-color: #ffffff;
  color: #DD3563;
  border-color: #DD3563;
}

input {
  font-size: 1rem;
  background-color: #ffffff;
}
input.form-control {
  background-color: #ffffff;
}
input.form-control:focus {
  border: 0.05rem solid #b0b0b0;
  outline: 0;
  box-shadow: none;
}
input.form-control:hover {
  border-color: #DD3563;
}
input.form-check-input:focus {
  border: 0.05rem solid #b0b0b0;
  outline: 0;
  box-shadow: none;
}
input.form-check-input:hover {
  border-color: #DD3563;
}
input[type=checkbox]:checked, input[type=radio]:checked {
  background-color: #DD3563;
  border-color: #DD3563;
}

.form-switch .form-check-input {
  height: 1.4rem;
  width: 2.5rem !important;
  cursor: pointer;
}

textarea.form-control:focus {
  border: 0.05rem solid #b0b0b0;
  outline: 0;
  box-shadow: none;
}
textarea.form-control:hover {
  border-color: #DD3563;
}

select.form-select:focus {
  border: 0.05rem solid #b0b0b0;
  outline: 0;
  box-shadow: none;
}
select.form-select:hover {
  border-color: #DD3563;
}
select.form-select option:hover {
  background-color: gray;
}

.custom-form {
  margin-top: 2rem;
}
.custom-form .grid-custom-two-column {
  display: flex;
  justify-content: end;
}
.custom-form .grid-custom-two-column .btn-custom-canceled,
.custom-form .grid-custom-two-column .btn-custom-secondary, .custom-form .grid-custom-two-column .btn-custom-desable {
  width: -moz-fit-content;
  width: fit-content;
}

.modal-logout .modal-dialog {
  width: 25rem !important;
  --bs-modal-width: auto;
}
.modal-logout .modal-dialog .modal-content {
  width: 100% !important;
  height: auto;
}
.modal-logout .card-custom .content.content-content p {
  font-size: 1.1rem;
  font-weight: 400;
}

.modal-delete-date-table .modal-dialog {
  width: 30rem !important;
  --bs-modal-width: auto;
}
.modal-delete-date-table .modal-dialog .modal-content {
  width: 100% !important;
  height: auto;
}
.modal-delete-date-table .card-custom .content.content-content .content-body {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-delete-date-table .card-custom .content.content-content .content-body p {
  font-size: 1.1rem;
  font-weight: 400;
}

.modal-training-add-optin-answer .modal-dialog {
  width: 30rem !important;
  --bs-modal-width: auto;
}
.modal-training-add-optin-answer .modal-dialog .modal-content {
  width: 100% !important;
  height: auto;
}
.modal-training-add-optin-answer .card-custom .content.content-content .content-body {
  display: grid;
  grid-template-columns: 1fr;
}
.modal-training-add-optin-answer .card-custom .content.content-content .content-body form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  margin: 1.5rem 0;
}

.modal-form-section-add .modal-dialog, .modal-form-section-edit .modal-dialog {
  width: 30rem !important;
  --bs-modal-width: auto;
}
.modal-form-section-add .modal-dialog .modal-content, .modal-form-section-edit .modal-dialog .modal-content {
  width: 100% !important;
  height: auto;
}
.modal-form-section-add .card-custom .content.content-content .content-body, .modal-form-section-edit .card-custom .content.content-content .content-body {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.modal-form-section-add .card-custom .content.content-content .content-body form, .modal-form-section-edit .card-custom .content.content-content .content-body form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  margin: 1.5rem 0;
}
.modal-form-section-add .card-custom .content.content-content .content-body form .item, .modal-form-section-edit .card-custom .content.content-content .content-body form .item {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0.5rem 0 1.5rem 0;
}

#admin-traning-section .section-content-full {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
#admin-traning-section .section-content-full .spinner-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#admin-traning-section .section-content-full .section-content {
  border: 0.05rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  padding: 1rem;
}
#admin-traning-section .section-content-full .section-content .icon {
  font-size: 0.8rem;
}
#admin-traning-section .section-content-full .section-content .edit {
  background-color: #00b809;
  padding: 0.2rem 0.6rem;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  border: 0.05rem solid #00b809;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#admin-traning-section .section-content-full .section-content .edit .icon {
  color: #ffffff;
}
#admin-traning-section .section-content-full .section-content .edit:hover {
  background-color: #ffffff;
}
#admin-traning-section .section-content-full .section-content .edit:hover .icon {
  color: #00b809;
}
#admin-traning-section .section-content-full .section-content .delete {
  background-color: #d70000;
  padding: 0.2rem 0.6rem;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  border: 0.05rem solid #d70000;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#admin-traning-section .section-content-full .section-content .delete .icon {
  color: #ffffff;
}
#admin-traning-section .section-content-full .section-content .delete:hover {
  background-color: #ffffff;
}
#admin-traning-section .section-content-full .section-content .delete:hover .icon {
  color: #d70000;
}
#admin-traning-section .section-content-full .section-content .title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
#admin-traning-section .section-content-full .section-content .title-section .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}
#admin-traning-section .section-content-full .section-content .title-section .item:first-child span {
  font-size: 1rem;
}
#admin-traning-section .section-content-full .section-content .title-section .item:first-child strong {
  font-weight: 500;
  font-size: 1.2rem;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter {
  background-color: rgba(254, 229, 232, 0.3215686275);
  padding: 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .title {
  font-size: 1rem;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .media {
  width: 100%;
  height: 9rem;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .media img,
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .media video {
  width: 100%;
  height: 100%;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin: 0.6rem 0 0.2rem 0;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete .edit {
  background-color: #ffffff;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete .edit .icon {
  color: #00b809;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete .edit:hover {
  background-color: #00b809;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete .edit:hover .icon {
  color: #ffffff;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete .delete {
  background-color: #ffffff;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete .delete .icon {
  color: #d70000;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete .delete:hover {
  background-color: #d70000;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .content .model-chapter .btn-edit-delete .delete:hover .icon {
  color: #ffffff;
}
#admin-traning-section .section-content-full .section-content .content-section-chapter .custom-btn-header {
  gap: 0.5rem;
}

#admin-section-quiz .content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
#admin-section-quiz .conetnt-quiz-list .spinner-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content {
  border: 0.05rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content {
  display: grid;
  grid-template-columns: 1fr;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .hearder-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .hearder-content span:first-child {
  font-size: 1rem;
  font-weight: 600;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .body-content {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .body-content .item:first-child {
  width: 100%;
  height: 9rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .body-content .item:first-child img,
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .body-content .item:first-child video {
  width: 100%;
  height: 100%;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .body-content .item:last-child {
  margin-top: 1rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .body-content .item:last-child span {
  font-size: 1rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .body-content .item:last-child span:last-child {
  font-weight: 600;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .footer-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(221, 53, 98, 0.1);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .footer-content .answer {
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  background-color: #ffffff;
  border: 0.05rem solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .footer-content .answer.correct {
  color: #00b809;
  border-color: #00b809;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .sub-content .footer-content .answer.error {
  color: #d70000;
  border-color: #d70000;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.3rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .icon {
  font-size: 0.8rem;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .edit {
  background-color: #ffffff;
  padding: 0.2rem 0.6rem;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  border: 0.05rem solid #00b809;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .edit .icon {
  color: #00b809;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .edit:hover {
  background-color: #00b809;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .edit:hover .icon {
  color: #ffffff;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .delete {
  background-color: #ffffff;
  padding: 0.2rem 0.6rem;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  border: 0.05rem solid #d70000;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  height: -moz-fit-content;
  height: fit-content;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .delete .icon {
  color: #d70000;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .delete:hover {
  background-color: #d70000;
}
#admin-section-quiz .conetnt-quiz-list .content-quiz .content .item-btn .delete:hover .icon {
  color: #ffffff;
}

#admin-section-forms-add .custom-form,
#admin-section-forms-edit .custom-form,
#admin-quiz-forms-add .custom-form,
#admin-quiz-forms-edit .custom-form {
  overflow-y: auto;
}
#admin-section-forms-add .custom-form::-webkit-scrollbar,
#admin-section-forms-edit .custom-form::-webkit-scrollbar,
#admin-quiz-forms-add .custom-form::-webkit-scrollbar,
#admin-quiz-forms-edit .custom-form::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.2rem;
}
#admin-section-forms-add .custom-form::-webkit-scrollbar-thumb,
#admin-section-forms-edit .custom-form::-webkit-scrollbar-thumb,
#admin-quiz-forms-add .custom-form::-webkit-scrollbar-thumb,
#admin-quiz-forms-edit .custom-form::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: rgba(89, 89, 89, 0.5);
  border-radius: 0.4;
  -webkit-border-radius: 0.4;
  -moz-border-radius: 0.4;
  -ms-border-radius: 0.4;
  -o-border-radius: 0.4;
}
#admin-section-forms-add .custom-form::-webkit-scrollbar-track,
#admin-section-forms-edit .custom-form::-webkit-scrollbar-track,
#admin-quiz-forms-add .custom-form::-webkit-scrollbar-track,
#admin-quiz-forms-edit .custom-form::-webkit-scrollbar-track {
  background-color: rgba(89, 89, 89, 0.2);
  border-radius: 20px;
}
#admin-section-forms-add .custom-form .body-formulaire .content-option-answers,
#admin-section-forms-edit .custom-form .body-formulaire .content-option-answers,
#admin-quiz-forms-add .custom-form .body-formulaire .content-option-answers,
#admin-quiz-forms-edit .custom-form .body-formulaire .content-option-answers {
  min-height: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(221, 53, 98, 0.1);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
#admin-section-forms-add .custom-form .body-formulaire .content-option-answers .answer,
#admin-section-forms-edit .custom-form .body-formulaire .content-option-answers .answer,
#admin-quiz-forms-add .custom-form .body-formulaire .content-option-answers .answer,
#admin-quiz-forms-edit .custom-form .body-formulaire .content-option-answers .answer {
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  background-color: #ffffff;
  border: 0.05rem solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
#admin-section-forms-add .custom-form .body-formulaire .content-option-answers .answer.correct,
#admin-section-forms-edit .custom-form .body-formulaire .content-option-answers .answer.correct,
#admin-quiz-forms-add .custom-form .body-formulaire .content-option-answers .answer.correct,
#admin-quiz-forms-edit .custom-form .body-formulaire .content-option-answers .answer.correct {
  color: #00b809;
  border-color: #00b809;
}
#admin-section-forms-add .custom-form .body-formulaire .content-option-answers .answer.error,
#admin-section-forms-edit .custom-form .body-formulaire .content-option-answers .answer.error,
#admin-quiz-forms-add .custom-form .body-formulaire .content-option-answers .answer.error,
#admin-quiz-forms-edit .custom-form .body-formulaire .content-option-answers .answer.error {
  color: #d70000;
  border-color: #d70000;
}
#admin-section-forms-add .custom-form .body-formulaire .link-add-option,
#admin-section-forms-edit .custom-form .body-formulaire .link-add-option,
#admin-quiz-forms-add .custom-form .body-formulaire .link-add-option,
#admin-quiz-forms-edit .custom-form .body-formulaire .link-add-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
#admin-section-forms-add .custom-form .body-formulaire .link-add-option span,
#admin-section-forms-edit .custom-form .body-formulaire .link-add-option span,
#admin-quiz-forms-add .custom-form .body-formulaire .link-add-option span,
#admin-quiz-forms-edit .custom-form .body-formulaire .link-add-option span {
  display: block;
}
#admin-section-forms-add .custom-form .body-formulaire .link-add-option span:first-child,
#admin-section-forms-edit .custom-form .body-formulaire .link-add-option span:first-child,
#admin-quiz-forms-add .custom-form .body-formulaire .link-add-option span:first-child,
#admin-quiz-forms-edit .custom-form .body-formulaire .link-add-option span:first-child {
  width: 1.6rem;
  height: 1.6rem;
  background-color: #DD3563;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
#admin-section-forms-add .custom-form .body-formulaire .link-add-option span:first-child .icon,
#admin-section-forms-edit .custom-form .body-formulaire .link-add-option span:first-child .icon,
#admin-quiz-forms-add .custom-form .body-formulaire .link-add-option span:first-child .icon,
#admin-quiz-forms-edit .custom-form .body-formulaire .link-add-option span:first-child .icon {
  color: #ffffff;
}

.custom-form .custom-display-media-description {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}
.custom-form .custom-display-media-without-description {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.custom-form .custom-display-media-without-description .content {
  position: relative;
  z-index: 1;
  height: 9rem;
}
.custom-form .custom-display-media-without-description .content .description {
  position: absolute;
  bottom: 0.2rem;
  right: 0.5rem;
  width: 7rem;
  z-index: 2;
  background-color: #00b809;
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0.05rem solid #00b809;
  cursor: pointer;
}
.custom-form .custom-display-media-without-description .content .description span {
  font-size: 0.8rem;
  font-weight: 400;
  color: #ffffff;
}
.custom-form .custom-display-media-without-description .content .description .icon-comment {
  color: #ffffff;
  font-size: 1rem;
}
.custom-form .custom-display-media-without-description .content .description:hover {
  background-color: #ffffff;
}
.custom-form .custom-display-media-without-description .content .description:hover span, .custom-form .custom-display-media-without-description .content .description:hover .icon-comment {
  color: #00b809;
}
.custom-form .custom-display-media-without-description .content .icon-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 3;
  cursor: pointer;
  color: #d70000;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
  background-color: transparent;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.custom-form .custom-display-media-without-description .content img, .custom-form .custom-display-media-without-description .content video {
  width: 9rem;
  height: 100%;
  cursor: pointer;
}

h4.title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #454545;
  width: -moz-fit-content;
  width: fit-content;
}
h4.title::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.1rem;
  background-color: #DD3563;
}

.cusom-go-back-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.custom-btn-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.custom-btn-header.custom-end {
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}
.custom-btn-header.custom-end .link-btn-custom .btn-custom-secondary,
.custom-btn-header.custom-end .link-btn-custom .btn-custom-primary,
.custom-btn-header.custom-end .link-btn-custom .btn-custom-green-icon {
  width: -moz-fit-content;
  width: fit-content;
}

#admin-fs button span.fs-kyc {
  color: #ffffff;
  display: block;
  margin-left: 1rem;
  font-weight: 500;
  font-size: 1.1rem;
}
#admin-fs button:hover span.fs-kyc {
  color: #00b809;
}

#admin-fs-validation .header-custom {
  display: grid;
  grid-template-columns: 6rem 1fr;
  margin-bottom: 1.5rem;
}
#admin-fs-validation .header-custom h5.title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#admin-fs-validation .item-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#admin-fs-validation .item-content .item-key-value {
  display: grid;
  grid-template-columns: 10rem 1fr;
}
#admin-fs-validation .kmedia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#admin-fs-validation .kmedia img {
  width: 18rem;
}

.admin-forms .btn-custom-primary-icon {
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1rem;
}

#admin-campaign-layouts .menu-campaign {
  margin-bottom: 1rem;
}
#admin-campaign-layouts .menu-campaign ul.custom-navbar-nav.light-bar {
  grid-template-columns: repeat(4, 1fr);
}

#admin-area-layouts .menu-area {
  margin-bottom: 1rem;
}
#admin-area-layouts .menu-area ul.custom-navbar-nav.light-bar {
  grid-template-columns: repeat(6, 1fr);
}

#admin-settings #menu-setting {
  margin-bottom: 1rem;
}
#admin-settings #menu-setting ul.custom-navbar-nav.light-bar {
  grid-template-columns: repeat(7, 1fr);
}
#admin-settings .admin-forms {
  margin-top: 3rem;
}

#admin-account .custom-display-info {
  gap: 1rem;
}
#admin-account .custom-display-info .loading-for-display-data {
  display: flex;
  justify-content: center;
  align-items: center;
}
#admin-account .custom-display-info .item-display {
  display: grid;
  grid-template-columns: 12rem 1fr;
}
#admin-account .custom-display-change-password {
  margin-top: 2rem;
}
#admin-account .custom-display-change-password form .item-form {
  display: grid;
  grid-template-columns: 1fr;
}
#admin-account .custom-display-change-password form .custom-btn-submit-position {
  display: flex;
  justify-content: flex-end;
}
#admin-account .custom-display-change-password form .custom-btn-submit-position button {
  width: -moz-fit-content;
  width: fit-content;
}

#admin-traning-edit .menu-traning {
  margin-top: 1rem;
}

.comapaign-forms .custom-form .item-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.comapaign-forms .custom-form .item-selected span {
  background: rgba(231, 231, 231, 0.5);
  color: #00b809;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.comapaign-forms .custom-form .item-selected span .icon {
  color: #d70000;
  font-size: 1.5rem;
  padding: 0.1rem;
  background: transparent;
  border: 0.05rem solid transparent;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.comapaign-forms .custom-form .item-selected span .icon:hover {
  border-color: #d70000;
}

* {
  margin: 0rem;
  padding: 0rem;
  box-sizing: border-box;
}

.grid-gap-custom-normal,
.grid-gap-custom-small,
.grid-custom-one-column,
.grid-custom-two-column,
.grid-custom-three-column,
.grid-custom-four-column,
.grid-custom-five-column,
.grid-custom-six-column,
.grid-custom-two-one-column,
.grid-custom-one-two-column {
  display: grid;
}

.grid-gap-custom-small {
  gap: 0.5rem;
}

.grid-gap-custom-normal {
  gap: 0.5rem;
}

.grid-gap-customv1 {
  gap: 0.5rem;
}

.grid-custom-one-column {
  grid-template-columns: 1fr;
}

.grid-custom-two-2one-column {
  grid-template-columns: 2fr repeat(2, 1fr);
}

.grid-custom-two-one-column {
  grid-template-columns: 2fr 1fr;
}

.grid-custom-one-two-column {
  grid-template-columns: 1fr 2fr;
}

.grid-custom-two-column {
  grid-template-columns: repeat(2, 1fr);
}

.grid-custom-three-column {
  grid-template-columns: repeat(3, 1fr);
}

.grid-custom-four-column {
  grid-template-columns: repeat(4, 1fr);
}

.grid-custom-five-column {
  grid-template-columns: repeat(5, 1fr);
}

p,
span,
div,
label,
input,
button {
  font-size: 1rem;
  color: #6d6d6d;
}

a,
.link-btn-custom {
  text-decoration: none;
}

.modal-form-custom-all .card-custom {
  position: relative;
  background-color: #ffffff;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.modal-form-custom-all .card-custom .close-icon {
  color: #d70000;
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  z-index: 3;
  font-size: 1.5rem;
}
.modal-form-custom-all .card-custom .content {
  padding: 1.5rem;
}/*# sourceMappingURL=Styles.css.map */